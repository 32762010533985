/* ==========================================================================
   Components Molecules - Blocks, Media Block
   ========================================================================== */

@import '../theme-bootstrap';

.media-block {
  position: relative;
  &--has-caption {
    .bg-img--expand {
      bottom: 37%; // 1% overlap to avoid gaps
    }
    .bg-img {
      background-position: center top;
    }
  }
  &__media {
    position: relative;
    @include swap_direction(padding, 0 5px);
    img {
      vertical-align: middle;
      width: 100%;
    } // removes bottom margin from image ~ http://stackoverflow.com/questions/10844205/html-5-strange-img-always-adds-3px-margin-at-bottom
    // when a media block is inside a carousel
    .basic-carousel & {
      background: $color--white;
    }
  }
  &__subheadline {
    line-height: 1;
    margin-bottom: 0.25em;
    // when inside a carousel give a default height (to fix 2x slide wide carousel headline vertical position)
    .carousel & {
      min-height: 0.95em;
    }
  }
  &__headline {
    @include h3();
    @include fontsize-rem($typesize--18px);
    color: $white;
    line-height: 1;
    margin-top: 0;
    @include breakpoint($bp--large-up) {
      @include fontsize-rem($typesize--30px);
    }
    @include breakpoint($bp--xlarge-up) {
      @include fontsize-rem($typesize--36px);
    }
    .custom-grid & {
      @include breakpoint($bp--medium-up) {
        @include fontsize-rem($typesize--24px);
      }
    }
  }
  &__caption {
    @include clearfix();
    @include reverse;
    color: $color--gray--lightest;
    height: 38%;
    @include swap_direction(padding, 15px);
    position: relative;
    width: 100%;
    &--two-col {
      @include layout(2) {
        > header {
          @include grid-span(1, 1);
          padding-#{$rdirection}: 5px;
        }
        > footer {
          @include grid-span(1, 2);
          padding-#{$ldirection}: 5px;
          @include breakpoint($bp--medium-down) {
            padding-#{$ldirection}: 0px;
          }
          .media-block__body {
            margin-top: 0;
          }
        }
      }
    }
  }
  &__body {
    line-height: 1.333333333;
    @include swap_direction(margin, 1.5em 0);
    p {
      @include swap_direction(margin, 0);
    }
  }
  &__cta {
    bottom: 15px;
    display: inline-block;
    line-height: 1.333333333;
    position: absolute;
    text-transform: uppercase;
    // when inside a carousel fix overlap with next previous buttons
    .carousel & {
      position: static;
    }
  }
  &--slideshow {
    // Slideshow Variant
    &__controls {
      text-align: center;
      > a {
        color: $white;
        float: #{$rdirection};
        font-size: 20px;
        font-weight: bold;
        &:hover {
          color: $color--gray--lightest;
        }
        &:first-child {
          float: #{$ldirection};
        }
      }
    }
  }
  &--video {
    // Video Variant
    .play {
      // for video
      @include abs-pos();
      bottom: 39%;
      color: $white;
      font-size: 47px;
      #{$ldirection}: 0;
      &:hover {
        color: $color--gray--lightest;
      }
    }
    .media-block__headline {
      @include fontsize-rem($typesize--18px);
      .custom-grid & {
        @include fontsize-rem($typesize--24px);
      }
    }
    &[data-eq-state='two-col'] {
      .media-block__caption {
        display: table;
        @include layout($grid--2) {
          header {
            @include grid-span(1, 1);
            padding-#{$rdirection}: 12px;
          }
          footer {
            @include grid-span(1, 2);
            .media-block__body {
              margin-top: 0;
            }
          }
        }
      }
    }
  }
  &--store {
    // Video Variant
    .media-block__caption {
      footer {
        .links {
          margin-top: 24px;
          a {
            display: table;
          }
        }
      }
    }
    &[data-eq-state='two-col'] {
      .media-block__caption {
        display: table;
        @include layout($grid--2) {
          header {
            @include grid-span(1, 1);
            padding-#{$rdirection}: 12px;
          }
          footer {
            @include grid-span(1, 2);
          }
        }
      }
    }
  }
  &.has-bg-img {
    .media-block__media {
      height: 100%;
      width: 100%;
    }
    .media-block__caption {
      bottom: 0;
      position: absolute;
    }
  }
}

// media blocks for video teasers
.media-block--video {
  .media-block__media--video {
    img {
      width: 100%;
    }
  }
  .media-block__caption--two-col > footer {
    float: none;
    @include breakpoint($bp--xxlarge-up) {
      float: #{$rdirection};
    }
  }
  .media-block__body {
    display: none;
    @include breakpoint($bp--xxlarge-up) {
      display: block;
    }
  }
  header .media-block--video-duration {
    @include breakpoint($bp--xxlarge-up) {
      display: none;
    }
  }
  footer .media-block--video-duration {
    display: none;
    @include breakpoint($bp--xxlarge-up) {
      display: block;
    }
  }

  // when a media block is inside a carousel
  .basic-carousel & {
    // provide a background color that matches the media block.
    // This fixes a bug in slick carousel that makes the background show briefly when changing slides.
    background: $color--white; /* Old browsers */
    background: -moz-linear-gradient(
      top,
      #ffffff 0%,
      #ffffff 61.7%,
      #000000 62.7%,
      #000000 100%
    ); /* FF3.6-15 */
    background: -webkit-linear-gradient(
      top,
      #ffffff 0%,
      #ffffff 61.7%,
      #000000 62.7%,
      #000000 100%
    ); /* Chrome10-25,Safari5.1-6 */
    background: linear-gradient(
      to bottom,
      #ffffff 0%,
      #ffffff 61.7%,
      #000000 62.7%,
      #000000 100%
    ); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
    filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#ffffff', endColorstr='#000000',GradientType=0 ); /* IE6-9 */
  }
}

.media-block--video-duration {
  position: absolute;
  header & {
    #{$rdirection}: $spacing;
    top: $spacing;
  }
  footer & {
    bottom: $spacing;
    #{$ldirection}: $spacing;
  }
}
